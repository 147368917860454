import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

const HeroOne = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section
        className='hero-section pt-100 background-img'
        style={{
          minHeight: '100vh',
          background:
            "url('assets/img/phoneandlogo.png')no-repeat center center / cover"
        }}>
        <div className='container'>
          <div className='row align-items-center justify-content-between py-5'>
            <div className='col-md-7 col-lg-6'>
              <div className='hero-content-left text-white'>
                <h1 className='text-white'>
                  <span>Download Now</span>
                </h1>
                <p className='lead'>
                  Capture moments and memories, express your thoughts with
                  anonymity and check out who's with who and where. Join our
                  waiting list to be the first to know when we launch.
                </p>
                {/* <form action='#' method='post' className='subscribe-form'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='text'
                      className='form-control input'
                      id='email'
                      name='email'
                      placeholder='info@yourdomain.com'
                    />
                    <input
                      type='submit'
                      className='button btn solid-btn'
                      id='submit'
                      value='Subscribe'
                    />
                  </div>
                </form> */}

                {/* <div className='video-promo-content py-4 d-flex align-items-center'>
                  <Link
                    to='#'
                    className='popup-youtube video-play-icon-without-bip video-play-icon mr-3'>
                    <span
                      className='ti-control-play'
                      onClick={() => setOpen(true)}></span>
                  </Link>
                  Watch Video Overview
                </div> */}

                <div className='download-btn mt-5'>
                  {/* <a href='#/' className='btn google-play-btn mr-3'>
                    <span className='ti-android'></span> Google Play
                  </a> */}
                  <a href='#/' className='btn app-store-btn'>
                    <span className='ti-apple'></span> App Store
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-5 col-lg-5'>
              <div className='hero-animation-img'>
                <img
                  src='assets/img/hero-phone.png'
                  alt='app'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
          <ModalVideo
            channel='youtube'
            isOpen={isOpen}
            videoId='9No-FiEInLA'
            onClose={() => setOpen(false)}
          />
        </div>
        <div className='bottom-img-absolute'>
          <img
            src='assets/img/hero-bg-shape-1.svg'
            alt='wave shape'
            className='img-fluid'
          />
        </div>
      </section>
    </>
  );
};

export default HeroOne;
