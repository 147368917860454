import React from 'react';

export default function FeatureImg({ ImgSource }) {
  return (
    <>
      <div id='features' className='feature-section ptb-100'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8'>
              <div className='section-heading text-center mb-5'>
                <span className='text-uppercase color-secondary sub-title'>
                  About MyClique
                </span>
                <h2>Some great features</h2>
                <p>
                  MyClique believes in inclusivity and bringing society together
                  by focusing on what we all love. We provide users with a safe
                  and positive environment to save memories, see what their
                  social circle is up to and meet new people. In MyClique it is
                  about the user, their experience, safety and privacy using our
                  app
                </p>
              </div>
            </div>
          </div>
          <div className='row row-grid align-items-center'>
            <div className='col-lg-4'>
              <div className='d-flex align-items-start mb-5'>
                <div className='pr-4'>
                  <div className='icon icon-shape  rounded-circle'>
                    <div className='download-img d-flex align-items-end'>
                      <img
                        src='assets/img/location-icon.png'
                        alt='download'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                </div>
                <div className='icon-text'>
                  <h5>Clique Map</h5>
                  <p className='mb-0'>
                    Check out the location of every active hangout
                  </p>
                </div>
              </div>
              <div className='d-flex align-items-start mb-5'>
                <div className='pr-4'>
                  <div className='icon icon-shape  rounded-circle'>
                    <div className='download-img d-flex align-items-end'>
                      <img
                        src='assets/img/hangouts2.png'
                        alt='download'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                </div>
                <div className='icon-text'>
                  <h5>Hangouts</h5>
                  <p className='mb-0'>
                    An album for every outing saved on everyone’s profiles and
                    shown to everyone's followers
                  </p>
                </div>
              </div>
              <div className='d-flex align-items-start'>
                <div className='pr-4'>
                  <div className='icon icon-shape  rounded-circle'>
                    <div className='download-img d-flex align-items-end'>
                      <img
                        src='assets/img/create-thought-icon.png'
                        alt='download'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                </div>
                <div className='icon-text'>
                  <h5>Thoughts</h5>
                  <p className='mb-0'>Anonymously micro blog your thoughts</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='position-relative' style={{ zindex: 10 }}>
                <img
                  alt='placeholder'
                  src={ImgSource}
                  className='img-center img-fluid'
                />
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='d-flex align-items-start mb-5'>
                <div className='pr-4'>
                  <div className='icon icon-shape  rounded-circle'>
                    <div className='download-img d-flex align-items-end'>
                      <img
                        src='assets/img/profile-icon.png'
                        alt='download'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                </div>
                <div className='icon-text'>
                  <h5>Login / Sign Up</h5>
                  <p className='mb-0'>Create a free account</p>
                </div>
              </div>
              <div className='d-flex align-items-start mb-5'>
                <div className='pr-4'>
                  <div className='icon icon-shape  rounded-circle'>
                    <div className='download-img d-flex align-items-end'>
                      <img
                        src='assets/img/love-icon.png'
                        alt='download'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                </div>

                <div className='icon-text'>
                  <h5>Follow friends</h5>
                  <p className='mb-0'>
                    Follow your friends to see their hangouts and thoughts
                  </p>
                </div>
              </div>
              <div className='d-flex align-items-start'>
                <div className='pr-4'>
                  <div className='icon icon-shape  rounded-circle'>
                    <div className='download-img d-flex align-items-end'>
                      <img
                        src='assets/img/places.png'
                        alt='download'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                </div>
                <div className='icon-text'>
                  <h5>Places</h5>
                  <p className='mb-0'>
                    My clique is all about places. Show people why your business
                    is amazing
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
