import React from 'react';

export default function Footer({ space }) {
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top background-img-2 ${
            space ? 'pt-150' : 'pt-60'
          }`}>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-12 col-lg-6 mb-4 mb-md-4 mb-sm-4 mb-lg-0'>
                <div className='footer-nav-wrap text-white'>
                  <img
                    src='assets/img/white-logo.png'
                    alt='footer logo'
                    width='120'
                    className='img-fluid mb-3'
                  />
                  <p>
                    MyClique aims at providing the best services for our
                    partners and users. We welcome all ideas and suggestions.
                    Stay in touch by subscribing to our newsletter or get in
                    touch with us if you have anything you would like our team
                    to know.{' '}
                  </p>

                  {/* <div className='social-list-wrap'>
                    <ul className='social-list list-inline list-unstyled'>
                      <li className='list-inline-item'>
                        <a href='#/' target='_blank' title='Facebook'>
                          <span className='ti-facebook'></span>
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='#/' target='_blank' title='Twitter'>
                          <span className='ti-twitter'></span>
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='#/' target='_blank' title='Instagram'>
                          <span className='ti-instagram'></span>
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='#/' target='_blank' title='printerst'>
                          <span className='ti-pinterest'></span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className='col-md-12 col-lg-6 flex align-items-center justify-content-center'>
                <div className='footer-nav-wrap text-white justify-self-end'>
                  <h5 className='mb-3 text-white'>Location</h5>
                  <ul className='list-unstyled support-list flex-dir-column'>
                    <li className='mb-2 d-flex align-items-center'>
                      <span className='ti-location-pin mr-2'></span>
                      SRTIP, Block B, 107
                      <br />
                      Dubai, United Arab Emirates
                    </li>
                    <li className='mb-2 d-flex align-items-center'>
                      <span className='ti-mobile mr-2'></span>
                      <a href='tel:+61283766284'> +971 (0) 52 708 6359</a>
                    </li>
                    <li className='mb-2 d-flex align-items-center'>
                      <span className='ti-email mr-2'></span>
                      <a href='mailto:info@my-clique.com'>
                        {' '}
                        info@my-clique.com
                      </a>
                    </li>
                    <li className='mb-2 d-flex align-items-center'>
                      <span className='ti-world mr-2'></span>
                      <a href='#/'> my-clique.com</a>
                    </li>
                  </ul>
                  {/* </div> */}
                </div>

                {/* <div className='col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0'>
                    <div className='footer-nav-wrap text-white'>
                      <h5 className='mb-3 text-white'>Resources</h5>
                      <ul className='list-unstyled'>
                        <li className='mb-2'>
                          <a href='#/'>Help</a>
                        </li>
                        <li className='mb-2'>
                          <a href='#/'>Events</a>
                        </li>
                        <li className='mb-2'>
                          <a href='#/'>Live Support</a>
                        </li>
                        <li className='mb-2'>
                          <a href='#/'>Open Sources</a>
                        </li>
                        <li className='mb-2'>
                          <a href='#/'>Documentation</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                {/* <div className='col-sm-6 col-md-6 col-lg-6 mb-6 mb-sm-6 mb-md-0 mb-lg-0 '>
                    <div className='footer-nav-wrap text-white'>
                      <h5 className='mb-3 text-white'>Company</h5>
                      <ul className='list-unstyled support-list'>
                        <li className='mb-2'>
                          <a href='#about'>About Us</a>
                        </li>
                        <li className='mb-2'>
                          <a href='#/'>Careers</a>
                        </li>
                        <li className='mb-2'>
                          <a href='#/'>Customers</a>
                        </li>
                        <li className='mb-2'>
                          <a href='#/'>Community</a>
                        </li>
                        <li className='mb-2'>
                          <a href='#team'>Our Team</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                {/* <div className='col-sm-6 col-md-6 col-lg-6'> */}
              </div>
            </div>
          </div>

          <div className='footer-bottom border-gray-light mt-5 py-3'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 col-lg-7'>
                  <div className='copyright-wrap small-text'>
                    <p className='mb-0 text-white'>
                      © MyClique FZC, All rights reserved
                    </p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-5'>
                  <div className='terms-policy-wrap text-lg-right text-md-right text-left'>
                    <ul className='list-inline'>
                      <li className='list-inline-item'>
                        <a className='small-text' href='/terms'>
                          Terms
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a className='small-text' href='/disclaimer'>
                          Disclaimer
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a className='small-text' href='/privacypolicy'>
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
