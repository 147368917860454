import React from 'react';
import OwlCarousel from 'react-owl-carousel';

export default function Screenshot({ hasBg }) {
  const options = {
    loop: true,
    margin: 0,
    center: true,
    dots: true,
    nav: false,
    autoplay: true,
    responsive: {
      0: {
        items: 2
      },
      768: {
        items: 3
      },
      991: {
        items: 4
      },
      1200: {
        items: 4
      },
      1920: {
        items: 4
      }
    }
  };
  return (
    <>
      <section
        id='screenshots'
        className={`screenshots-section ptb-100 ${
          hasBg ? 'gray-light-bg' : ''
        }`}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8'>
              <div className='section-heading text-center'>
                {hasBg ? (
                  <span className='text-uppercase color-secondary sub-title'>
                    App Screenshots
                  </span>
                ) : (
                  ''
                )}
                <h2>A glimpse of what to expect</h2>
                <p className='lead'>
                  Show people what you’re up to. Keep a personal map marking
                  your places and share your thoughts in ghost mode.
                </p>
              </div>
            </div>
          </div>
          <div className='screen-slider-content mt-5'>
            <div className='screenshot-frame'></div>
            <OwlCarousel
              className='screen-carousel owl-carousel owl-theme dot-indicator'
              {...options}>
              <img
                src='assets/img/shots1.png'
                // className='img-fluid'
                alt='screenshots'
              />
              <img
                src='assets/img/shots2.png'
                // className='img-fluid'
                alt='screenshots'
              />
              <img
                src='assets/img/shots3.png'
                // className='img-fluid'
                alt='screenshots'
              />
              <img
                src='assets/img/shots4.png'
                // className='img-fluid'
                alt='screenshots'
              />
              {/* <img
                src='assets/img/shots5.png'
                // className='img-fluid'
                alt='screenshots'
              /> */}
              <img
                src='assets/img/shots6.png'
                // className='img-fluid'
                alt='screenshots'
              />
              <img
                src='assets/img/shots7.png'
                // className='img-fluid'
                alt='screenshots'
              />

              {/* <img
                src='assets/img/shots9.png'
                // className='img-fluid'
                alt='screenshots'
              />
              <img
                src='assets/img/10.png'
                // className='img-fluid'
                alt='screenshots'
              />
              <img
                src='assets/img/11.png'
                // className='img-fluid'
                alt='screenshots'
              />
              <img
                src='assets/img/12.png'
                // className='img-fluid'
                alt='screenshots'
              /> */}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
}
