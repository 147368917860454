import React from 'react';
import { Link } from 'react-router-dom';

const Cta = () => {
  return (
    <>
      <section
        id='about'
        className='download-section pt-100 background-img'
        style={{
          background:
            "url('assets/img/map.png') no-repeat center center / cover"
        }}>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-md-7'>
              <div className='download-content text-white pb-100'>
                <h2 className='text-white'>About MyClique</h2>
                <p className='lead'>
                  We are a group of friends who want a new way to save their
                  hangouts and discover new places to visit on their next
                  outing. We believe that we grow by focusing on our
                  similarities and what we all love are hangouts! We want our
                  app to actually encourage people to be social, meet up with
                  their cliques and meet new people to expand their circle.
                </p>

                <div className='download-btn'>
                  <Link to='/' className='btn google-play-btn mr-3'>
                    <span className='ti-android'></span> Google Play
                  </Link>
                  <Link to='/' className='btn app-store-btn'>
                    <span className='ti-apple'></span> App Store
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='download-img d-flex align-items-end'>
                <img
                  src='assets/img/hand-image.png'
                  alt='download'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
